.wrapper1 {
    margin-top: 20px;
    padding: 13.5rem 7rem 3.5rem 5.25rem;
    max-width: 1250px;
    margin: 0 auto;
}

.wrapper2 {
    padding: 0 7rem 3.5rem 5.25rem;
    max-width: 1250px;
    margin: 0 auto;
}

.container {
    text-align: center;
    height: 20vh;
}

.update {
    margin-top: 3rem;
    font-size: 1.75rem;
    color: #6b6b6b;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .wrapper1 {
        padding: 13.5rem 2.5rem 3.5rem 3.25rem;
    }

    .wrapper2 {
        padding: 0 2.5rem 3.5rem 3.25rem;
    }
}

@media screen and (max-width: 600px) {
    .wrapper1 {
        padding: 13.5rem 1.5rem 3.5rem 3.25rem;
    }

    .wrapper2 {
        padding: 0 1.5rem 3.5rem 3.25rem;
    }

    .update {
        font-size: 1.5em;
    }
}