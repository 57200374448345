.container {
   width: 50%;
   margin: 1rem;
   margin-top: 2rem;
   border: 0.5rem solid #e8e8e8;
   padding: 1rem 2rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   font-family: 'Poppins', sans-serif;
   position: relative;
}

.inputGrp {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 1rem;
   width: 100%;
}

.input {
   font-family: inherit;
   padding: 0.6rem;
   border-radius: 8px;
   background-color: transparent;
   font-size: 1rem;
   text-align: center;
   color: var(--primary);
   border: 2px solid var(--primary);
   margin-block: 1rem;
   width: 100%;
}

.textarea {
   padding: 0.6rem;
   border-radius: 8px;
   background-color: transparent;
   font-size: 1rem;
   text-align: center;
   color: var(--primary);
   border: 2px solid var(--primary);
   margin-block: 1rem;
   width: 100%;
   font-family: inherit;
}



.textarea:focus {
   outline: none;
   background-color: var(--primary);
   color: #e8e8e8;
   box-shadow: 5px 5px var(--accent);
}

::placeholder {
   color: var(--primary);
}

.input:focus {
   outline: none;
   background-color: var(--primary);
   color: #e8e8e8;
   box-shadow: 5px 5px var(--accent);
}

.input:focus::placeholder {
   color: #e8e8e8;
}

/* .btnContainer {
 position: absolute;
} */

.btn {
   font-family: inherit;
   display: inline-block;
   width: 8em;
   height: 2.6em;
   line-height: 2.5em;
   margin: 20px;
   position: relative;
   overflow: hidden;
   border: 2px solid var(--accent);
   transition: color 0.5s;
   z-index: 1;
   font-size: 17px;
   border-radius: 6px;
   font-weight: 500;
   color: var(--accent);
}

.btn:before {
   content: '';
   position: absolute;
   z-index: -1;
   background: var(--accent);
   height: 150px;
   width: 200px;
   border-radius: 50%;
}

.btn:hover {
   color: #fff;
}

.btn:before {
   top: 100%;
   left: 100%;
   transition: all 0.7s;
}

.btn:hover:before {
   top: -30px;
   left: -30px;
}

.btn:active:before {
   background: transparent;
   transition: background 0s;
}

@media screen and (max-width: 1100px) {
   .container {
      width: 90%;
      margin-bottom: 3rem;
   }
}

@media screen and (max-width: 500px) {
   .container {
      width: 100%;
   }

   .inputGrp {
      flex-direction: column;
      row-gap: 0rem;
   }

   .input {
      font-size: 0.8rem;
      margin-block: 0.5rem;
   }

   .btn {
      font-size: 0.8rem;
   }

   .textarea {
      font-size: 0.8rem;
   }
}