.footer {
   background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/images/footer-bg.jpg');
   background-size: cover;
   background-position: top;
   background-repeat: no-repeat;
   font-size: 0.8rem;
   color: var(--bg);
   border-top: 0.7rem solid var(--accent);
   font-weight: 300;
   padding: 2rem 0rem;
   text-align: center;
   bottom: 0;
   left: 0;
   position: relative;
   width: 100%;
}

.logo {
   height: auto;
   width: 15rem;
   margin: auto;
   margin-block: 2rem;
}

.logoImg {
   width: 100%;
   height: 100%;
}

.heading {
   font-size: 2rem;
   font-weight: 800;
   margin-bottom: 2rem;
   letter-spacing: 2px;
}

.socialIcons {
   display: flex;
   justify-content: center;
   align-items: center;
}

.socialLinks {
   width: 4rem;
   height: 4rem;
   text-align: center;
   text-decoration: none;
   color: var(--bg);
   box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
   margin: 0 1rem;
   border-radius: 50%;
   position: relative;
   overflow: hidden;
   transition: transform 0.5s;
   font-size: 2rem;
   line-height: 80px;
   transition: all 0.5s;
   z-index: 100;
   background-color: var(--secondary);
}

.socialLinks::after {
   content: '';
   width: 100%;
   height: 100%;
   top: -90px;
   left: 0;
   background: #000;
   background: var(--accent);
   position: absolute;
   transition: 0.5s;
   z-index: -1;
}

.socialLinks:hover::after {
   top: 0;
}

.socialLinks:hover {
   transform: translateY(-10px);
   color: #fff;
}

.copyrights {
   text-align: center;
   font-size: 1.2rem;
   margin-top: 2rem;
}

@media only screen and (max-width: 1024px) {
   .footer {
      flex-direction: column;
      padding: 4rem 2rem;
      justify-content: center;
      align-items: center;
   }
}

@media only screen and (max-width: 768px) {
   .footer {
      font-size: 0.6rem;
      padding: 2rem 1rem 1rem 1rem;
   }

   .socialLinks {
      width: 3rem;
      height: 3rem;
      line-height: 60px;
      font-size: 1.5rem;
   }

   .copyrights {
      font-size: 0.5rem;
      width: 100%;
   }
   
   .logo {
      width: 10rem;
      margin-block: 1rem;
   }
}
