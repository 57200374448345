.heading {
    position: fixed;
    width: 100%;
    margin-top: 4.5rem;
    padding: 1.8rem 0rem 1.8rem 7rem;
    background: var(--goldStripGradient);
    z-index: 10;
}

.zoomWrapper {
    max-width: 1500px;
    margin: 0 auto;
}

.title {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 1.75rem;
    letter-spacing: 1.5px;
    color: #ffffff;
    -webkit-animation: text-shadow-drop-center 0.6s both;
    animation: text-shadow-drop-center 0.6s both;
}

@-webkit-keyframes text-shadow-drop-center {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
    }
}

@keyframes text-shadow-drop-center {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
    }
}

@media screen and (max-width: 950px) {
    .heading {
        padding: 1.8rem 0rem;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: 1.6rem;
    }
}