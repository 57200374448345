.section {
    max-width: 1250px;
    margin: 0 auto;
    padding: 13.5rem 5rem 3.5rem 5rem;
    font-size: 1.35rem;
    letter-spacing: 1.25px;
    line-height: 1.6;
}

.container {
    padding: 0 0.3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
}

.list1 {
    padding-left: 20px;
}

.partner {
    margin: 0 0 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partner img {
    width: 300px;
}

.partner p {
    text-align: center;
    font-weight: 600;
}

.section p,
.section li {
    color: rgb(119, 119, 119);
}

.section a {
    color: var(--secondary);
    font-weight: 600;
}

.journal {
    margin: 1.5rem 0rem;
    padding-left: 1.3rem;
}

@media screen and (max-width: 1200px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container div:first-child {
        flex: 1;
        margin-bottom: 2rem;
    }

    .partner {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {

    .section {
        padding: 13.5rem 3.5rem 3.5rem 4.5rem;
    }
}

@media screen and (max-width: 620px) {
    .section {
        padding: 12.5rem 1.5rem 3.5rem 2rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 490px) {

    .section h2 {
        font-size: 1rem;
    }

    .section p,
    .section li {
        font-size: 1.1rem;
    }

    .container {
        padding: 0 0 0 20px;
    }

    .journal {
        padding-left: 0.5rem;
    }

    .partner img {
        width: 250px;
    }

    ul {
        padding-left: 1.5rem;
    }
}