.landing {
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
}

.bg {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/bg.jpg');
   height: 100vh;
   width: 100%;
   background-size: cover;
   background-position: top;
   background-repeat: no-repeat;
   position: relative;
}

.heroText {
   text-align: center;
   color: #fff;
   font-size: 2.8rem;
   position: absolute;
   top: 38%;
   left: 50%;
   transform: translate(-50%, -55%);
  
}

.s1 {
   font-family: 'Poppins', sans-serif;
   display: block;
   font-weight: 200;
   font-size: 2.0rem;
   line-height: 3.0rem;
}



.s2 {
   margin-block-start: 0.5rem;
   display: block;
   font-size: 8.5rem;
   font-weight: 700;
   letter-spacing: 0.2rem;
}

.s3 {
   display: block;
   font-family: 'Poppins', sans-serif;
   letter-spacing: 2rem;
}

.subText {
   width: 100%;
   position: absolute;
   bottom: 4rem;
   left: 50%;
   transform: translateX(-50%);
   text-align: center;
   color: #fff;
   font-size: 2.5rem;
   text-align: center;
}

.sb {
   margin-bottom: 0.5rem;
   display: block;
}

.sb1 {
   font-family: 'Poppins', sans-serif;
   font-weight: 300;
   display: block;
   font-size: 1rem;
}

.venueSection {
   width: 100%;
   text-align: center;
   margin: 2rem 0rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.venueContainer {
   width: 100%;
   padding-top: 1rem;
   display: flex;
}

.map {
   padding: 1rem;
   width: 65%;
   height: 70vh;
}

.howtoReach {
   padding: 2rem;
   padding-left: 3rem;

   width: 35%;
   position: relative;
}

.howtoReach h2 {
   font-size: 2.5rem;
   font-weight: 700;
   margin-bottom: 1rem;
   color: var(--accent);
}

.howtoReach p {
   font-size: 1.6rem;
   line-height: 2.3rem;
   font-weight: 300;
   color: var(--primary);
}

.howtoReach div {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -80%);
   width: 80%;
   margin-left: auto;
}

.scroll_wrapper {
   width: 100%;
   position: absolute;
   bottom: 0rem;
   left: 50%;
   transform: translateX(-50%);
}

.scroll {
   display: flex;
   position: relative;
   width: 100%;
   height: 10px;
   padding: 1.5rem;
   margin: auto;
   background-color: rgba(48, 83, 162, 0.65);
   overflow: hidden;
   z-index: 1;
}

.scroll_title {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   position: absolute;
   top: 50%;
   left: 0;
   transform: translateY(-50%);
   width: 100%;
   height: 50%;
   white-space: nowrap;
   transition: all 1s ease;
}

.scroll_title > div {
   display: flex;
   animation: scrollText 33s infinite linear;
}

.scroll_title p {
   margin: 0;
   font-size: 1.5rem;
   color: rgba(255, 255, 255, 1);
   transition: all 2s ease;
}

.scroll_title p span {
   color: rgb(242, 255, 0);
}

@keyframes scrollText {
   from {
      transform: translateX(0%);
   }

   to {
      transform: translateX(-50%);
   }
}

@media only screen and (max-width: 1024px) {
   .heroText {
      font-size: 2.5rem;
   }

   
   .s1{
      font-size: 1.2rem;
      line-height: 2rem;
   }
   .s2 {
      font-size: 7rem;
   }

   .subText {
      bottom: 5rem;
      font-size: 2.3rem;
   }
}

@media only screen and (max-width: 768px) {
   .bg {
      height: 90vh;
   }

   .heroText {
      font-size: 1.5rem;
      top: 40%;
   }

   .s1{margin-top: 3rem;}
  
   .s2 {
      font-size: 3.3rem;
   }

   .s3 {
      letter-spacing: 1rem;
   }

   .subText {
      font-size: 1.5rem;
   }

   .sb1 {
      font-size: 0.8rem;
   }

   .venueContainer {
      flex-direction: column-reverse;
      padding: 0;
   }

   .map {
      width: 100%;
      height: 50vh;
      padding: 0;
      padding-top: 1rem;
   }

   .howtoReach {
      width: 100%;
      padding: 1rem;
   }

   .howtoReach div {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 100%;
      padding: 0;
      margin: auto;
   }

   .howtoReach h2 {
      font-size: 1.7rem;
   }

   .howtoReach p {
      font-size: 1.2rem;
      line-height: 1.8rem;
   }

   .scroll_title p {
      font-size: 1.25rem;
   }
}
