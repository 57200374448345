.section {
    max-width: 1250px;
    margin: 0 auto;
    padding: 13.5rem 5rem 3.5rem 5rem;
    font-size: 1.35rem;
    letter-spacing: 1.25px;
    line-height: 1.4;
}

.container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
}

.container div:first-child {
    flex: 0.9;
}

.section ul {
    padding-left: 1.5rem;
}

.section li {
    color: rgb(119, 119, 119);
    margin: 1rem 0;
}

.section a {
    color: var(--secondary);
    font-weight: 600;
}

.format {
    max-width: 1000px;
}

@media screen and (max-width: 1100px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container div:first-child {
        flex: 1;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 900px) {

    .section {
        padding: 13.5rem 3.5rem 3.5rem 4.5rem;
    }
}

@media screen and (max-width: 600px) {
    .section {
        padding: 12.5rem 1.5rem 3.5rem 2.2rem;
        font-size: 1.2rem;
    }

    .section ul {
        padding-left: 2rem;
    }

}

@media screen and (max-width: 400px) {
    .section h2 {
        font-size: 1.1rem;
        word-wrap: break-word;
    }
}