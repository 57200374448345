@font-face {
   font-family: 'GlacialIndifference';
   src: url('./assets/fonts/GlacialIndifference-Regular.otf') format('opentype');

   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'GlacialIndifference';
   src: url('./assets/fonts/GlacialIndifference-Bold.otf') format('opentype');

   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'GlacialIndifference';
   src: url('./assets/fonts/GlacialIndifference-Italic.otf') format('opentype');

   font-weight: normal;
   font-style: italic;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body {
   margin: 0;
   font-family: 'GlacialIndifference', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-block: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  border-radius: 10px;
}