.wrapper {
    margin-top: 20px;
    padding: 13.5rem 7rem 3.5rem 5.25rem;
    max-width: 1250px;
    margin: 0 auto;
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 2.5rem;
    text-align: center;
    margin: 3rem 0;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        padding: 13.5rem 2.5rem 3.5rem 3.25rem;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        padding: 13.5rem 1.5rem 3.5rem 3.25rem;
    }
}