.section {
   max-width: 1250px;
   margin: 0 auto;
   padding: 13.5rem 3.5rem 3.5rem 4.5rem;
}

.track {
   margin: 2.2rem 0rem;
   padding: 0.8rem 0.5rem;
   border: 1px solid black;
   box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.2);
   border-radius: 20px;
}

.track h2 {
   margin: 0;
   padding-left: 1rem;
   font-size: 1.3rem;
   color: var(--primary);
   letter-spacing: 0.7px;
   cursor: pointer;
}

.track h2 i {
   font-style: normal;
   color: var(--accent);
}

.description {
   text-align: justify;
   padding-right: 2rem;
   font-size: 1.3rem;
   letter-spacing: 1.25px;
   line-height: 1.4;
   color: #6b6b6b;
}

.track > div {
   margin-top: 1rem;
   display: flex;
   justify-content: space-around;
   align-items: stretch;
}

.track ul {
   margin: 1rem;
   padding: 1rem 2rem;
   flex: 1;
   color: var(--secondary);
   border: 2px solid rgb(168, 168, 168);
   list-style: none;
}

.track ul li::before {
   content: '\2022';
   color: var(--primary);
   font-weight: bold;
   display: inline-block;
   width: 1em;
   margin-left: -0.7em;
}

.track ul li {
   font-family: 'Roboto', sans-serif;
   font-size: 1.2rem;
   padding: 0.4rem 0rem;
   letter-spacing: 0.8px;
   line-height: 1.6;
}

.icon {
   color: var(--accent) !important;
   font-size: 2rem !important;
}

@media screen and (max-width: 700px) {
   .section {
      padding: 12.5rem 1.5rem 3.5rem 3.5rem;
   }

   .track > div {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
   }

   .description {
      padding-right: 0;
   }
}

@media screen and (max-width: 600px) {
   .track h2 {
      font-size: 1rem;
   }

   .track ul {
      margin: 1rem 0rem;
   }

   .track ul li {
      font-size: 0.8rem;
   }

   .description {
      font-size: 1rem;
   }
}
