.wrapper {
    padding: 13.5rem 5.25rem 3.5rem;
    max-width: 1250px;
    margin: 0 auto;
}

.wrapper div:nth-child(2) {
    margin-top: 4rem;
}

.description {
    font-size: 1.35rem;
    letter-spacing: 1.25px;
    line-height: 1.4;
    color: #6b6b6b;
    text-align: justify;
}

@media screen and (max-width: 600px) {
    .wrapper {
        padding: 13.5rem 2rem 3.5rem 3.5rem;
    }

    .description {
        text-align: justify;
        font-size: 1.2rem;
    }
}