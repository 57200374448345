.section {
    max-width: 1250px;
    margin: 0 auto;
    padding: 10.5rem 5.25rem 3.5rem;
}


.flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}

.container {
    margin: 1rem 0rem;
    padding-top: 1rem;
    color: #6b6b6b;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
}

.table {
    width: min(90%, 1000px);
    margin-top: 1.5rem;
    padding: 0rem 1.5rem;
    border: 1px solid rgb(148, 148, 148);
}

.table p {
    margin: 0;
    padding: 1em 0em;
    font-size: 1.2rem;
    border-bottom: 1px solid rgb(148, 148, 148);
    letter-spacing: 1.2px;
}

.table p:last-child {
    border: none;
}

.table p i {
    font-style: normal;
    color: var(--primary);
    font-weight: bold;
}

@-webkit-keyframes text-shadow-drop-bottom {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
    }
}

@keyframes text-shadow-drop-bottom {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 1000px) {
    .section {
        padding: 10.5rem 3.5rem 3.5rem 5rem;
    }

    .flex,
    .center,
    .container {
        display: block;
    }

    .center {
        padding-left: 0.5rem;
    }

    .container {
        margin: 1.5rem 0rem;
    }
}

@media screen and (max-width: 600px) {
    .section {
        padding: 10.5rem 3rem 3.5rem 3.5rem;
    }

    .table p {
        font-size: 1rem;
    }
}