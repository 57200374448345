.table {
    margin: 40px auto 0;
    border: 2px solid grey;
    width: min(1000px, 100%);
    table-layout: fixed;
    border-collapse: collapse;
}

.header {
    background: var(--primary);
    border: 3px solid var(--primary);
    color: whitesmoke;
    font-size: 1.25rem;
    height: 70px;
    letter-spacing: 1.4px;
}

.header_events {
    text-align: left;
    padding-left: 3rem;
}

.header_dates {
    text-align: right;
    padding-right: 8rem;
}

.content {
    height: 60px;
    font-size: 1.2rem;
    font-weight: 600;
}

.events {
    color: grey;
    text-align: left;
    padding-left: 3rem;
}

.date {
    color: var(--primary);
    text-align: right;
    padding-right: 7rem;
}

.date sup {
    font-size: 1rem;
    padding-left: 0.15rem;
}

.line {
    width: 95%;
}

.lastline {
    border-color: transparent;
}

s{text-decoration:none;position:relative;
    margin-right: 1rem;
    font-size: 1rem;
}
s::before {
    top: 50%; 
    background:red; 
    opacity:.7;
    content: '';
    width: 100%;
    position: absolute;
    height:.15em;
    border-radius:.1em;
    left: 0%;
    white-space:nowrap;
    display: block;
    transform: rotate(-10deg);  
}

s::after {
    top: 50%; 
    background:red; 
    opacity:.7;
    content: '';
    width: 100%;
    position: absolute;
    height:.15em;
    border-radius:.1em;
    left: 0%;
    white-space:nowrap;
    display: block;
    transform: rotate(10deg);  
}
s.straight::before, strike.straight::before{transform: rotate(0deg);left:-1%;width:102%;}

.date > div {
    display: inline-block;
}


@media screen and (max-width: 900px) {

    .header,
    .content {
        font-size: 1.2rem;
        height: 60px;
    }

    .header_dates {
        padding-right: 5rem;
    }

    .date {
        padding-right: 3rem;
    }
}

@media screen and (max-width: 600px) {

    .header,
    .content {
        font-size: 1.1rem;
    }

    .header_dates {
        padding-right: 3rem;
    }

    .date {
        padding-right: 1.5rem;
    }

    .date sup {
        font-size: 0.9rem;
        padding-left: 0.1rem;
    }

    .events {
        padding-left: 1.5rem;
    }
}

@media screen and (max-width: 450px) {

    .header,
    .content {
        font-size: 1rem;
    }

    .header_events {
        padding-left: 1.5rem;
    }

    .header_dates {
        padding-right: 2rem;
    }

    .date {
        padding-right: 0.75rem;
    }

    .events {
        padding-left: 1rem;
    }
}