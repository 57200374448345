.nav {
   background-color: var(--bg);
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   width: 100%;
   height: 4.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1.5rem;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1000;
}

.logo {
   height: auto;
   width: 12rem;
}

.logoImg {
   width: 100%;
   height: 100%;
}

.navLinks {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}

.navItems {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   list-style: none;
}

.navItem {
   color: var(--primary);
   margin: 0 1.2rem;
   font-size: 1.1rem;
   cursor: pointer;
   text-align: center;
}

.navLink {
   font-weight: 600;
   padding-bottom: 2px;
}

.navLink:hover {
   color: var(--bg);
   border-bottom: 3px solid var(--primary);
}

.navLink:active,
.navLink:link,
.navLink:visited,
.navLink:focus {
   color: var(--primary);
   text-decoration: none;
}

.navLink.active {
   color: #fff;
   background-color: var(--primary);
   padding: 0.5rem;
   border-radius: 0.5rem;
}

.navLink.active:hover {
   border-bottom: none;
}

.menuBtn {
   display: none;
}

.nav_mobile {
   display: none;
   height: 100vh;
   width: 75%;
   padding: 3rem 1rem;
   position: absolute;
   right: -20px;
   top: 0;
   background-color: var(--bg);
   transition: all 0.5s ease;
   transform: translateX(900px);
}

.floater {
   text-transform: uppercase;
   font-size: 1rem;
   letter-spacing: 1px;
   background-color: transparent;
   position: fixed;
   top: 70%;
   left: 0rem;
   transform: rotate(270deg) translateY(50%);
   z-index: 1001;
   font-weight: bold;
   transform-origin: 0;
   text-align: left;
   display: flex;
   gap: 1rem;
   width: 100vh;
}

.floaterLink {
   color: var(--bg);
   text-decoration: none;
}

.floater1 {
   background-color: var(--accent);
   padding: 0.5rem 1rem;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.floater2 {
   background-color: var(--secondary);
   padding: 0.5rem 1rem;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.activeMob {
   transform: translateX(0);
}

.navItems_mobile {
   list-style: none;
   width: 100%;
   height: 70%;
   display: flex;
   flex-direction: column;
   align-items: center;
   display: none;
   padding-top: 2rem;
}

.navItem_mobile {
   margin-block: 1rem;
}

.nav_toggle {
   display: none;
   position: absolute;
   right: 2rem;
   top: 22px;
   cursor: pointer;
   padding: 10px 35px 16px 0px;
   z-index: 101;
}

.nav_toggle span,
.nav_toggle span:before,
.nav_toggle span:after {
   cursor: pointer;
   border-radius: 1px;
   height: 4px;
   width: 30px;
   background: var(--primary);
   position: absolute;
   display: block;
   content: '';
   transition: all 300ms ease-in-out;
}

.nav_toggle span:before {
   top: -10px;
}

.nav_toggle span:after {
   bottom: -10px;
}

.nav_toggle.active span {
   background-color: transparent;
}

.nav_toggle.active span:before,
.nav_toggle.active span:after {
   top: 0;
}

.nav_toggle.active span:before {
   transform: rotate(45deg);
}

.nav_toggle.active span:after {
   transform: rotate(-45deg);
}

.menu {
   position: relative;
}

.menu > .navLink {
   padding-bottom: 20px;
}

.dropdown {
   display: none;
   padding: 1rem 1.5rem 1rem 1rem;
   position: absolute;
   background: var(--secondary);
   color: white;
   top: 25px;
}

.dropdown .navLink {
   color: white;
   display: block;
   letter-spacing: 1px;
}

.menu > .navLink:hover,
.dropdown .navLink:nth-child(2):hover {
   border-bottom: none;
}

.dropdown .navLink:first-child {
   padding-bottom: 15px;
   margin-bottom: 15px;
   border-bottom: 1px solid white;
}

.menu:hover .dropdown,
.dropdown:hover {
   display: block;
}

@media screen and (max-width: 1250px) {
   .navItem {
      margin: 0 0.75rem;
      font-size: 1.05rem;
   }

   .dropdown {
      top: 22px;
   }
}

@media screen and (max-width: 1150px) {
   .navItem {
      margin: 0 0.6rem;
      font-size: 1rem;
   }

   .dropdown {
      top: 20px;
   }
}

@media only screen and (max-width: 1024px) {
   .nav_mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
   }

   .navItems_mobile {
      display: flex;
   }

   .nav_toggle {
      display: block;
   }

   .nav {
      padding: 0 0rem 0rem 1rem;
   }

   .logo {
      width: 10rem;
   }

   .navItems {
      display: none;
   }

   .menuBtn {
      color: var(--primary);
      display: block;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
   }

   .navButton {
      display: none;
   }

   .nav-mobile {
      display: block;
   }

   .floater {
      font-size: 0.7rem;
   }
}
