.table {
    margin: 40px auto 0;
    border: 2px solid grey;
    width: min(100%, 1000px);
    table-layout: fixed;
    border-collapse: collapse;
    letter-spacing: 1px;
    word-break: break-all;
}

.header {
    background: var(--primary);
    border: 3px solid var(--primary);
    color: whitesmoke;
    font-size: 1.25rem;
    height: 75px;
}

.header th:first-child {
    padding-left: 2rem;
}

.content {
    height: 60px;
    font-size: 1.2rem;
    align-items: center;
    text-align: center;
    color: var(--primary);
}

.category {
    padding-left: 40px;
}

.mobile {
    display: none;
}

.line {
    width: 95%;
}

.lastline {
    border-color: transparent;
}

@media screen and (max-width: 1100px) {

    .header,
    .content {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 700px) {

    .header,
    .content {
        font-size: 1rem;
        min-height: 50px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .category {
        padding-left: 20px;
    }
}

@media screen and (max-width: 500px) {

    .header,
    .content {
        min-height: 50px;
    }

    .header th:first-child {
        padding-left: 1rem;
    }

    .header {
        font-size: 0.75rem;
    }

    .content {
        font-size: 0.85rem;
    }
}