.wrapper {
    margin-top: 20px;
    padding: 13.5rem 5.25rem 3.5rem;
    max-width: 1250px;
    margin: 0 auto;
}

.description {
    font-size: 1.4rem;
    letter-spacing: 1.25px;
    line-height: 1.4;
    color: #6b6b6b;
    text-align: justify;
}

.description i {
    color: var(--primary);
    font-style: normal;
    font-weight: 600;
    display: block;
    margin-top: 1.2rem;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        padding: 13.5rem 2.5rem 3.5rem 3.25rem;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        padding: 12.5rem 1.5rem 3.5rem 3.25rem;
    }

    .description {
        font-size: 1.2rem;
    }
}

.submit_button {
    font-family: "Poppins";
    --color: #fff;
    --background: #404660;
    --background-hover: var(--secondary);
    --background-left: var(--secondary);
    --folder: #F3E9CB;
    --folder-inner: #BEB393;
    --paper: #FFFFFF;
    --paper-lines: #BBC1E1;
    --paper-behind: #E1E6F9;
    --pencil-cap: #fff;
    --pencil-top: #275EFE;
    --pencil-middle: #fff;
    --pencil-bottom: #5C86FF;
    --shadow: rgba(13, 15, 25, .2);
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding: 17px 29px 17px 69px;
    transition: background 0.3s;
    color: var(--color);
    background: var(--secondary);
    display: block;
    margin: auto;
    margin-top: 2rem   ;
    
  }
  
  .submit_button > div {
    top: 0;
    left: 0;
    bottom: 0;
    width: 53px;
    position: absolute;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    background: var(--background-left);
  }
  
  .submit_button > div .folder {
    width: 23px;
    height: 27px;
    position: absolute;
    left: 15px;
    top: 13px;
  }
  
  .submit_button > div .folder .top {
    left: 0;
    top: 0;
    z-index: 2;
    position: absolute;
    transform: translateX(var(--fx, 0));
    transition: transform 0.4s ease var(--fd, 0.3s);
  }
  
  .submit_button > div .folder .top svg {
    width: 24px;
    height: 27px;
    display: block;
    fill: var(--folder);
    transform-origin: 0 50%;
    transition: transform 0.3s ease var(--fds, 0.45s);
    transform: perspective(120px) rotateY(var(--fr, 0deg));
  }
  
  .submit_button > div .folder:before, .submit_button > div .folder:after,
  .submit_button > div .folder .paper {
    content: "";
    position: absolute;
    left: var(--l, 0);
    top: var(--t, 0);
    width: var(--w, 100%);
    height: var(--h, 100%);
    border-radius: 1px;
    background: var(--b, var(--folder-inner));
  }
  
  .submit_button > div .folder:before {
    box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
    transform: translateX(var(--fx, 0));
    transition: transform 0.4s ease var(--fd, 0.3s);
  }
  
  .submit_button > div .folder:after,
  .submit_button > div .folder .paper {
    --l: 1px;
    --t: 1px;
    --w: 21px;
    --h: 25px;
    --b: var(--paper-behind);
  }
  
  .submit_button > div .folder:after {
    transform: translate(var(--pbx, 0), var(--pby, 0));
    transition: transform 0.4s ease var(--pbd, 0s);
  }
  
  .submit_button > div .folder .paper {
    z-index: 1;
    --b: var(--paper);
  }
  
  .submit_button > div .folder .paper:before, .submit_button > div .folder .paper:after {
    content: "";
    width: var(--wp, 14px);
    height: 2px;
    border-radius: 1px;
    transform: scaleY(0.5);
    left: 3px;
    top: var(--tp, 3px);
    position: absolute;
    background: var(--paper-lines);
    box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
  }
  
  .submit_button > div .folder .paper:after {
    --tp: 6px;
    --wp: 10px;
  }
  
  .submit_button > div .pencil {
    height: 2px;
    width: 3px;
    border-radius: 1px 1px 0 0;
    top: 8px;
    left: 105%;
    position: absolute;
    z-index: 3;
    transform-origin: 50% 19px;
    background: var(--pencil-cap);
    transform: translateX(var(--pex, 0)) rotate(35deg);
    transition: transform 0.4s ease var(--pbd, 0s);
  }
  
  .submit_button > div .pencil:before, .submit_button > div .pencil:after {
    content: "";
    position: absolute;
    display: block;
    background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
    width: var(--w, 5px);
    height: var(--h, 20px);
    border-radius: var(--br, 2px 2px 0 0);
    top: var(--t, 2px);
    left: var(--l, -1px);
  }
  
  .submit_button > div .pencil:before {
    -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
    clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  }
  
  .submit_button > div .pencil:after {
    --b: none;
    --w: 3px;
    --h: 6px;
    --br: 0 2px 1px 0;
    --t: 3px;
    --l: 3px;
    border-top: 1px solid var(--pencil-top);
    border-right: 1px solid var(--pencil-top);
  }
  
  .submit_button:before, .submit_button:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background: var(--color);
    transform-origin: 9px 1px;
    transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
    top: 26px;
    right: 16px;
    transition: transform 0.3s;
  }
  
  .submit_button:after {
    --r: 45deg;
  }
  
  .submit_button:hover {
    --cx: 2px;
    --bg: var(--background-hover);
    --fx: -40px;
    --fr: -60deg;
    --fd: .15s;
    --fds: 0s;
    --pbx: 3px;
    --pby: -3px;
    --pbd: .15s;
    --pex: -24px;
  }
  