.section {
   max-width: 1300px;
   margin: 0 auto;
   padding: 12.5rem 3.5rem 3.5rem 5rem;
}

.container {
   display: flex;
   justify-content: center;
   align-items: center;
}

.rightContainer {
   width: 45%;
   padding-left: 2rem;
   gap: 0.5rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.card {
   font-size: 1.35rem;
   letter-spacing: 1.25px;
   line-height: 1.4;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-bottom: 2rem;
   text-align: center;
   gap: 1rem;
   color: var(--primary);
}

.name {
   font-weight: bold;
}

.phone {
   font-weight: bold;
}

.phoneNo {
   color: var(--text);
   margin-left: 0.8rem;
   font-weight: normal;
}

.divider {
   width: 80%;
   height: 2px;
   padding-inline: 1rem;

   background-color: #d7d7d7;
}

.emailCard {
   font-size: 1.3rem;
   letter-spacing: 1.25px;
   line-height: 1.4;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-block: 2rem;

   gap: 1rem;
   color: var(--primary);
}

@media screen and (max-width: 1100px) {
   .container {
      flex-direction: column;
      justify-content: stretch;
   }

   .rightContainer {
      width: 100%;
      padding-left: 0;
   }
}

@media screen and (max-width: 650px) {
   .card {
      font-size: 1rem;
      /* line-height: 0; */
      margin-block: 1rem;
   }

   .emailCard {
      font-size: 1rem;
   }
}